<template>
  <div class="py-5">
    <div class="container px-5">
      <div class="galeria">
        <LayoutFoto
          :img-src="this.foto1"
          img-alt="Imagen 1"
          img-class="col-lg-3 col-md-4 col-sm-6"
        />
        <LayoutFoto
          :img-src="this.foto2"
          img-alt="Imagen 2"
          img-class="col-lg-3 col-md-4 col-sm-6"
        />
        <LayoutFoto
          :img-src="this.foto3"
          img-alt="Imagen 3"
          img-class="col-lg-3 col-md-4 col-sm-6"
        />
        <LayoutFoto
          :img-src="this.foto4"
          img-alt="Imagen 4"
          img-class="col-lg-3 col-md-4 col-sm-6"
        />
        <LayoutFoto
          :img-src="this.foto5"
          img-alt="Imagen 5"
          img-class="col-lg-3 col-md-4 col-sm-6"
        />
        <LayoutFoto
          :img-src="this.foto6"
          img-alt="Imagen 6"
          img-class="col-lg-3 col-md-4 col-sm-6"
        />
        <LayoutFoto
          :img-src="this.foto7"
          img-alt="Imagen 7"
          img-class="col-lg-3 col-md-4 col-sm-6"
        />
        <LayoutFoto
          :img-src="this.foto8"
          img-alt="Imagen 8"
          img-class="col-lg-3 col-md-4 col-sm-6"
        />
        <LayoutFoto
          :img-src="this.foto9"
          img-alt="Imagen 9"
          img-class="col-lg-3 col-md-4 col-sm-6"
        />
        <LayoutFoto
          :img-src="this.foto10"
          img-alt="Imagen 10"
          img-class="col-lg-3 col-md-4 col-sm-6"
        />
        <LayoutFoto
          :img-src="this.foto11"
          img-alt="Imagen 11"
          img-class="col-lg-3 col-md-4 col-sm-6"
        />
        <LayoutFoto
          :img-src="this.foto12"
          img-alt="Imagen 12"
          img-class="col-lg-3 col-md-4 col-sm-6"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LayoutFoto from "@/components/LayoutFoto.vue";

export default {
  name: "Layout3",
  props: {
    foto1: String,
    foto2: String,
    foto3: String,
    foto4: String,
    foto5: String,
    foto6: String,
    foto7: String,
    foto8: String,
    foto9: String,
    foto10: String,
    foto11: String,
    foto12: String,
  },
  components: {
    LayoutFoto,
  },
  setup() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.galeria {
  overflow: hidden;
}
</style>
