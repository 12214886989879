<template>
    <div class="main">
        {{ texto }}
    </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Cobro",
  data() {
        return {
            texto: ""
        };
    },
    mounted() {
        this.llamarApi();
    },
  methods: {
    async llamarApi() {
        try {
            console.log('llegue');
            const response = await axios.get("http://localhost:3000/test");
            console.log(response.data, 'response.data');
            this.texto = response.data;
        } catch (error) {
            console.log(error, 'error');
        }
    }
  }
};
</script>

<style scoped>
</style>
