<template>
  <div class="layout">
    <Layout
      foto1="/img/DSC0013.jpg"
      foto2="/img/_DSC0323.jpg"
      foto3="/img/DSC0418.jpg"
      foto4="/img/DSC_0459.jpg"
      foto5="/img/_DSC0073.jpg"
      foto6="/img/DSC_0764.jpg"
      foto7="/img/DSC_0778.jpg"
      foto8="/img/DSC_0462.jpg"
      foto9="/img/DSC_0174.jpg"
      foto10="/img/DSC_0487.jpg"
      foto11="/img/DSC_0097.jpg"
      foto12="/img/DSC_0045.jpg"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Layout from "@/components/Layout3x4.vue";

export default {
  name: "LayoutView",
  components: {
    Layout,
  },
};
</script>
