<template>
  <div class="cobro">
    <Cobro />
  </div>
</template>

<script>
import Cobro from "@/components/Cobro.vue";

export default {
  name: "CobroView",
  components: {
    Cobro
  },
};
</script>

<style scoped></style>
